/**
 * объект содержащий названия табов UserCard
 * */
export const UserCardTabsNames = {
    Info: 'Инфо',
    //Plans: 'Планы'
    Learning: 'Учеба',
    Work: 'Места работы',
    Activity: 'Активности',
    Facts: 'Факты'
};