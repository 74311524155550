import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './BottomMenu.scss';
import { BottomMenuItems } from '../common/BottomMenuItems';
import TimeIconSvg from '../icons/TimeIconSvg';
import NotificationIconSvg from '../icons/NotificationIconSvg';
import UserSearchIconSvg from '../icons/UserSearchIconSvg';
import ProfileIconSvg from '../icons/ProfileIconSvg';
import MessageIconSvg from '../icons/MessageIconSvg';
import Routes from '../common/Routes';

import UpdateIconSvg from '../icons/UpdateIconSvg';
import { useSelector } from 'react-redux';
import usePostsStore from '../hooks/usePostsStore';





export default function BottomMenu() {

    const postsStore = usePostsStore();

    const onUpdate = async () => await postsStore.update();    

    return (
                <div className="BottomMenu position-relative" >
                    <div className="Menu col-12 position-fixed bottom-0 start-50 translate-middle-x mb-2">

                        <button className={"UpdateButton"} disabled={postsStore.isLoading} onClick={onUpdate}>
                            {postsStore.isLoading?"Loading...":"Update"} <UpdateIconSvg  />
                        </button>

                    </div>
    </div >);
    
}
