import React, { Component, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import { UserCardTabsNames } from '../../common/UserCardTabsNames';
import useAccountStore from '../../hooks/useAccountStore';
import './SwiperCities.scss';


interface ICity {
    name: string
    id: number
}

interface SwiperTabsProps {
    cities: ICity[]
    selectedCityId: any
    onClickCity: any

    user?: any
}

export default function SwiperCities(props: SwiperTabsProps) {
    const { currentUser } = useAccountStore();


    return (
        <div className="SwiperTabsConatainer d-flex justify-content-center">
            <Swiper
                freeMode={true}
                slidesPerView={'auto'}
                spaceBetween={20}
                className={'SwiperCities rounded-pill'}
            >
                {(() => {                    
                    return props.cities && props.cities.map((item: ICity, i: any) =>
                        <SwiperSlide
                            key={i}
                            className={'Tab ' + (props.selectedCityId === item.id ? 'Button bg-blue fw-bold ' : 'Button bg-transparent')}
                            onClick={() => props.onClickCity(item.id)}
                        //onTouchEnd={() => props.setSelectedTab(item)}
                        >
                            <div className="fs-6 d-flex justify-content-center align-items-center">
                                <span className="me-2">{item.name}</span>                                
                            </div>
                        </SwiperSlide>
                    );
                    
                })()}
            </Swiper>
        </div>
    );
}