/***
 * Объект содержащий пункты нижнего меню
 * */
export const BottomMenuItems = {
    UserSearch: 'UserSearch',
    //Messanger: 'Messanger',
    // объявления
    // ...
    Profie: 'Profile',
    Meetings: 'Meetings',
    Notifications: 'Notifications',
    TimeTable: 'TimeTable'
}