import { AppThunk, RootState } from './createStore';
import { createAction, createSlice } from '@reduxjs/toolkit';
import postsService from '../api/PostsService';
import cityService from '../api/CityService';

export interface IPostsState {
    selectedCityId: number,
    cities: [],
    posts: []
    isLoading: boolean
    error: string | null
    dataLoaded: boolean
}

const initialState: IPostsState = {
    selectedCityId: 0,
    cities: [],
    posts: [],
    isLoading: false,
    error: null,
    dataLoaded: false 
}

const postsSlice = createSlice({
    name: 'posts',
    initialState: initialState,
    reducers: {
        selectedCityUpdated: (state, action) => {
            state.selectedCityId = action.payload;
        },
        citiesReceived: (state, action) => {
            state.cities = action.payload;
        },


        postsRequested: state => {
            state.isLoading = true;
            state.dataLoaded = false;
        },
        postsReceived: (state, action) => {
            state.posts = action.payload;
            state.isLoading = false;
            state.dataLoaded = true;
            state.error = null;
        },
        postsFailed: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
            state.dataLoaded = true;
        },
    }
});

const { actions, reducer: postsReducer } = postsSlice;
const { postsRequested, postsReceived, postsFailed, selectedCityUpdated, citiesReceived } = actions;

export const updatePosts = (): AppThunk => async (dispatch, getState) => {

    await dispatch(postsRequested());

    try {
        const cityId: number = getState().posts.selectedCityId;

        if (cityId != 0) {
            var payload = await postsService.getPostsByCity(cityId);
            await dispatch(postsReceived(payload));
        }
    } catch (err: any) {
        await dispatch(postsFailed(err.message));
        throw err;
    }
}

export const selectCity = (id:number): AppThunk => async (dispatch, getState) => {

    await dispatch(selectedCityUpdated(id));
}

export const loadCities = (): AppThunk => async (dispatch, getState) => {

    try {

        var payload = await cityService.getList();

        var selectedCityId = 0;
        if (payload.length > 0) {
            selectedCityId = payload[0].id;
        }


        await dispatch(citiesReceived(payload));        

        await dispatch(selectedCityUpdated(selectedCityId));

    } catch (err: any) {
        await dispatch(postsFailed(err.message));
        throw err;
    }
}



export default postsReducer;