import React, { Component, useState } from 'react';

export default function WeightIconSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36.145" height="26.576" viewBox="0 0 36.145 26.576">
            <g id="weight-body-scales-control" transform="translate(-6 -79)">
                <g id="Group_78" data-name="Group 78" transform="translate(6 79)">
                    <path id="Path_27" data-name="Path 27" d="M43.462,34.094a2.036,2.036,0,0,0-2.539.249l-1.6,1.707V28.089c0-2.257-2.159-4.086-4.819-4.089H12.819C10.159,24,8,25.832,8,28.089v18.4c0,2.257,2.159,4.086,4.819,4.089H34.507c2.66,0,4.816-1.832,4.819-4.089v-5.5l4.43-4.742A1.384,1.384,0,0,0,43.462,34.094ZM36.013,48.021A1.036,1.036,0,1,1,37.218,47,1.125,1.125,0,0,1,36.013,48.021ZM15.53,27.066a.282.282,0,0,1,.3-.256H31.495a.282.282,0,0,1,.3.256V30.9a.282.282,0,0,1-.3.256H15.832a.282.282,0,0,1-.3-.256Zm21.688.511a1.221,1.221,0,1,1-1.2-1.022A1.125,1.125,0,0,1,37.218,27.578ZM10.109,47a1.125,1.125,0,0,1,1.2-1.022,1.036,1.036,0,1,1,0,2.044A1.126,1.126,0,0,1,10.109,47Zm1.2-18.4a1.036,1.036,0,1,1,0-2.044,1.036,1.036,0,1,1,0,2.044ZM21.856,50.065V46.232A1.687,1.687,0,0,1,23.663,44.7a1.687,1.687,0,0,1,1.807,1.533v3.833ZM43.284,35.93l-4.508,4.827-3.083,3.3a1.283,1.283,0,0,1-.932.387h-.012a1.294,1.294,0,0,1-.932-.374L30.563,40.7a.9.9,0,0,1-.158-1.084,1.236,1.236,0,0,1,1.087-.586c.041,0,.082,0,.124.005a1.265,1.265,0,0,1,.812.371L34.5,41.545a.337.337,0,0,0,.235.095.325.325,0,0,0,.233-.1l4.292-4.6.018-.019L41.4,34.66a1.355,1.355,0,0,1,1.693-.166.922.922,0,0,1,.2,1.436Z" transform="translate(-8 -24)" fill="#fff" />
                </g>
            </g>
        </svg>
    );
}