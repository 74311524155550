import React, { Component, useState } from 'react';

export default function UpdateIconSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33.13" height="37.442" viewBox="0 0 33.13 37.442">
            <g id="to_update" transform="translate(1.24 1.825)">
                <g id="Group_128" data-name="Group 128">
                    <path id="Path_40" data-name="Path 40" d="M20.786,25.11a11.011,11.011,0,0,1,6.979-12.019A11.214,11.214,0,0,1,38.273,14.38l-2.4,3.839,10.77-.041L42.793,7.15,40.815,10.31A16.808,16.808,0,0,0,26.02,8.7,15.728,15.728,0,0,0,15.964,25.11Z" transform="translate(-15.866 -7.15)" fill="#390e0e" stroke="#707070" strokeWidth="1.589" />
                    <path id="Path_41" data-name="Path 41" d="M54.224,185.412a10.811,10.811,0,0,1-6.85,11.8,11.256,11.256,0,0,1-9.857-.932c.539-.866,2.131-3.41,2.131-3.41l-11.367-.666,4.814,11.161,2.023-3.223a15.557,15.557,0,0,0,23.84-14.731Z" transform="translate(-28.281 -169.451)" fill="#390e0e" stroke="#707070" strokeWidth="1.589" />
                </g>
            </g>
        </svg>


    );
}