import React, { Component, SetStateAction, useEffect, useRef, useState } from 'react';
import postsService from '../../api/PostsService';
//import { Link, useHistory } from 'react-router-dom';
//import Select from 'react-select/creatable';
//import userService from '../../api/UserService';
//import { Helmet } from 'react-helmet';
//import { getAvatarPathForUser, objectToFormData } from '../../common/Utils';
//import GoalItem from './GoalItem';
//import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import './Contacts.scss';



export default function Contacts() {


    useEffect(() => {

    }, []);


    return (<>
        <div className="row">
            <div>Contact Us</div>
        </div>

        <div className="row">
            <div className="col-6">Address:</div>
            <div className="col-6">Address:</div>
            <div className="col-6">Address:</div>
            <div className="col-6">Address:</div>
        </div>
    </>);
}
