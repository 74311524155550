import React, { Component, useState } from 'react';

export default function CalendarIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 36.124 38.532">
        <g id="weekly-calendar-outline-event-interface-symbol_icon-icons.com_73108" transform="translate(8 -2)">
            <g id="_x35__16_" transform="translate(-8 2)">
                <g id="Group_34" data-name="Group 34">
                    <path id="Path_21" data-name="Path 21" d="M27.625,24.082h2.408a2.408,2.408,0,0,0,2.408-2.408V19.266a2.408,2.408,0,0,0-2.408-2.408H27.625a2.408,2.408,0,0,0-2.408,2.408v2.408A2.408,2.408,0,0,0,27.625,24.082Zm0-4.816h2.408v2.408H27.625ZM46.891,33.715H49.3a2.408,2.408,0,0,0,2.408-2.408V28.9A2.408,2.408,0,0,0,49.3,26.491H46.891A2.408,2.408,0,0,0,44.482,28.9v2.408A2.408,2.408,0,0,0,46.891,33.715Zm0-4.816H49.3v2.408H46.891Zm0-4.816H49.3a2.408,2.408,0,0,0,2.408-2.408V19.266A2.408,2.408,0,0,0,49.3,16.858H46.891a2.408,2.408,0,0,0-2.408,2.408v2.408A2.408,2.408,0,0,0,46.891,24.082Zm0-4.816H49.3v2.408H46.891ZM51.707,2.408H46.891V1.2a1.2,1.2,0,1,0-2.408,0v1.2H32.441V1.2a1.2,1.2,0,0,0-2.408,0v1.2H25.216A4.817,4.817,0,0,0,20.4,7.225V33.715a4.817,4.817,0,0,0,4.816,4.817H51.707a4.817,4.817,0,0,0,4.816-4.817V7.225A4.817,4.817,0,0,0,51.707,2.408Zm2.408,31.307a2.408,2.408,0,0,1-2.408,2.408H25.216a2.408,2.408,0,0,1-2.408-2.408V12.041H54.115V33.715Zm0-24.082H22.808V7.225a2.408,2.408,0,0,1,2.408-2.408h4.816v1.2a1.2,1.2,0,0,0,2.408,0v-1.2H44.482v1.2a1.2,1.2,0,0,0,2.408,0v-1.2h4.817a2.408,2.408,0,0,1,2.408,2.408ZM37.258,33.715h2.408a2.408,2.408,0,0,0,2.408-2.408V28.9a2.408,2.408,0,0,0-2.408-2.408H37.258A2.408,2.408,0,0,0,34.849,28.9v2.408A2.408,2.408,0,0,0,37.258,33.715Zm0-4.816h2.408v2.408H37.258Zm0-4.816h2.408a2.408,2.408,0,0,0,2.408-2.408V19.266a2.408,2.408,0,0,0-2.408-2.408H37.258a2.408,2.408,0,0,0-2.408,2.408v2.408A2.408,2.408,0,0,0,37.258,24.082Zm0-4.816h2.408v2.408H37.258ZM27.625,33.715h2.408a2.408,2.408,0,0,0,2.408-2.408V28.9a2.408,2.408,0,0,0-2.408-2.408H27.625A2.408,2.408,0,0,0,25.216,28.9v2.408A2.408,2.408,0,0,0,27.625,33.715Zm0-4.816h2.408v2.408H27.625Z" transform="translate(-20.4)" />
                </g>
            </g>
        </g>
    </svg>);
}