import React, { Component, SetStateAction, useEffect, useRef, useState } from 'react';
import postsService from '../../api/PostsService';
//import { Link, useHistory } from 'react-router-dom';
//import Select from 'react-select/creatable';
//import userService from '../../api/UserService';
//import { Helmet } from 'react-helmet';
//import { getAvatarPathForUser, objectToFormData } from '../../common/Utils';
//import GoalItem from './GoalItem';
//import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import './PostsList.scss';
import usePostsStore from '../../hooks/usePostsStore';

import moment from 'moment';
import 'moment-timezone';

import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'
import SwiperTabs from '../../modules/tabs/SwiperTabs';
import SwiperCities from './SwiperCities';
import CalendarIcon from '../../icons/CalendarIcon';


//import GoBackIcon from '../../icons/GoBackIcon';
//import SlidersIcon from '../../icons/SlidersIcon';
//import UserSearchFilterModal, { IFilter } from '../../modules/entities/user/UserSearchFilterModal';

//import useUsersStore from '../../hooks/useUsersStore';
//import Routes from '../../common/Routes';

export default function PostsList() {

    const postsStore = usePostsStore();

    const [posts, setPosts] = useState<any>([]);

    const [cities, setCities] = useState<any>([]);
    const [selectedCityId, setSelectedCityId] = useState<number>(1);

    useEffect(() => {
        const func = async () => {
            await postsStore.loadCities();
            await postsStore.update();
        }

        func();

    }, []);

    useEffect(() => {
        var func = async () => {
            var posts = await postsService.getPostsByCity(selectedCityId);
            setPosts(posts);
        }

        func();
    }, [selectedCityId]);

    const _updateUI = async () => {

        var data = postsService.getPosts();
        setPosts(data);
    }


    const _onSelectCity = async (id: number) => {
        setSelectedCityId(id);

        postsStore.selectCity(id);
        postsStore.update();
    }


    return (<>
        

       <div className="Cities">
                <SwiperCities cities={postsStore.cities} selectedCityId={postsStore.selectedCityId} onClickCity={(id: number) => _onSelectCity(id)}/>
        </div>

        <div className="PostList">
                    
                {postsStore.posts.map((x: any) => 
                    <div className="Post">
                        <div className="Header d-flex justify-content-between">
                            <div className="Info d-flex justify-content-left">
                                <div className="Avatar">
                                    <img className="Avatar" src={'assets/group_ava.jpg'} alt="" />

                                </div>
                                <div className="Info">
                                    <div className="d-flex justify-content-between">                        
                                        <a target="_blank" href={"https://www.facebook.com/groups/" + x.groupId} className="Group d-inline">{x.groupName}</a> 
                                    </div>

                                    {x.userId?
                                        <a target="_blank" href={ "https://www.facebook.com/profile.php?id=" + x.userId} className="Author">{x.authorName}</a>
                                        :
                                        <span className="Author">{x.authorName}</span>
                                    }
                                    <div className="Date d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div className="mx-2">
                                            Date: {moment(x.postDate).format('DD.MM.YYYY HH:mm')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Buttons">
                                
                                <a className="PostButton d-flex justify-content-center " target="_blank" href={"https://www.facebook.com/groups/" + x.groupId + "/posts/" + x.postId}>
                                    <svg className="align-self-center" xmlns="http://www.w3.org/2000/svg" width="15.922" height="15.922" viewBox="0 0 15.922 15.922">
                                        <g id="Group_130" data-name="Group 130" transform="translate(0 -218.5)">
                                            <path id="arrow-up-right-from-square-solid" d="M9.951,0a1,1,0,0,0,0,1.99h2.572L6.263,8.253A1,1,0,0,0,7.672,9.662L13.932,3.4V5.971a1,1,0,0,0,1.99,0V1a.994.994,0,0,0-1-1ZM2.488,1A2.487,2.487,0,0,0,0,3.483v9.951a2.487,2.487,0,0,0,2.488,2.488h9.951a2.487,2.487,0,0,0,2.488-2.488V9.951a1,1,0,0,0-1.99,0v3.483a.5.5,0,0,1-.5.5H2.488a.5.5,0,0,1-.5-.5V3.483a.5.5,0,0,1,.5-.5H5.971a1,1,0,0,0,0-1.99Z" transform="translate(0 218.5)" />
                                        </g>
                                    </svg>

                                </a>
                            </div>

                        </div>
                        
                        {/*<div dangerouslySetInnerHTML={{ __html: createDOMPurify.sanitize(x.content) }} className="Content" style={{ overflow: "hidden" }}></div>*/}

                        <div className="Content" >{x.content }</div>
                       

                    </div>
                )}                
              
    </div></>
    );
}
