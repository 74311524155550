
import { objectToFormData } from "../common/Utils";
import UserDTO from "../contracts/user/UserDTO";
import BaseService from "./BaseService";
import httpService from "./BaseService";
import axios from "axios";


const axiosClient = axios.create({
    baseURL: '',
});

class CityService extends BaseService {
    // AllowAnonymous
    async getList() {        
        var res = [];
        try {
            const { data } = await axiosClient.post(`/api/city/GetList`);
            return data;
        }
        catch (err) {
            var e = err;

        }

        return res;
    }
    
};


const cityService = new CityService();
export default cityService;
