import React, { Component, useState } from 'react';

export default function EditIconSvg() {
    return (
        <svg id="feather_edit" data-name="feather / edit" xmlns="http://www.w3.org/2000/svg" width="21.999" height="21.999" viewBox="0 0 21.999 21.999">
            <defs>
                <clipPath id="clip-path">
                    <path id="Combined_Shape" data-name="Combined Shape" d="M17,22H3a3,3,0,0,1-3-3V5A3,3,0,0,1,3,2H8.34a1,1,0,0,1,0,2H3A1,1,0,0,0,2,5V19a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V13.66a1,1,0,1,1,2,0V19A3,3,0,0,1,17,22Zm-6-6H7a1,1,0,0,1-1-1V11a1.006,1.006,0,0,1,.293-.707l10-10a1,1,0,0,1,1.415,0l4,4a1,1,0,0,1,0,1.414l-10,10A1,1,0,0,1,11,16ZM17,2.414h0l-9,9V14h2.586l9-9L17,2.414Z" transform="translate(0 0)" fill="#fff" />
                </clipPath>
            </defs>
            <g id="Group_41" data-name="Group 41" transform="translate(0 0)">
                <path id="Combined_Shape-2" data-name="Combined Shape" d="M17,22H3a3,3,0,0,1-3-3V5A3,3,0,0,1,3,2H8.34a1,1,0,0,1,0,2H3A1,1,0,0,0,2,5V19a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V13.66a1,1,0,1,1,2,0V19A3,3,0,0,1,17,22Zm-6-6H7a1,1,0,0,1-1-1V11a1.006,1.006,0,0,1,.293-.707l10-10a1,1,0,0,1,1.415,0l4,4a1,1,0,0,1,0,1.414l-10,10A1,1,0,0,1,11,16ZM17,2.414h0l-9,9V14h2.586l9-9L17,2.414Z" transform="translate(0 0)" fill="#fff" />
                <g id="Mask_Group_41" data-name="Mask Group 41" clip-path="url(#clip-path)">
                    <g id="COLOR_black" data-name="COLOR/ black" transform="translate(-1 -1)">
                        <rect id="Rectangle" width="24" height="24" fill="#fff" />
                    </g>
                </g>
            </g>
        </svg>
    );
}