import * as React from 'react';
import { Component } from 'react';
import Layout from './shared/Layout';


const App = () => {

    return (<>
        <Layout />
    </>);
}

export default App;