import React, { Component, useState } from 'react';

interface IProps{
    width?: any
    height?: any
}

export default function CoffeeIcon(props: IProps) {
    return (
        <svg id="fe1b952e61a08962d5143ab7ac524d60" xmlns="http://www.w3.org/2000/svg" width={props.width || "30.343"} height={props.height || "27.248"} viewBox={`0 0 ${props.width || "30.343"} ${props.height || "27.248"}`}>
            <path id="Path_28" data-name="Path 28" d="M30.256,80.234a11.822,11.822,0,0,1-2.9,2.112V83.5H16.552V82.349a11.871,11.871,0,0,1-6.484-10.577V68.441l23.755.006s-.006.48-.015,1.078c2.065-.071,6.038-.093,6.447,3.83.864,3.781-4.7,5.611-10,6.88Zm8.115-6.93c-.48-1.917-2.607-1.725-4.53-1.66,0,.1,0,.142,0,.127a11.811,11.811,0,0,1-1.926,6.456c3.421-1.1,6.942-2.811,6.459-4.923ZM19.137,64.118c0,1.619-3.465,2.236-3.465,2.236S17.44,65.2,17.44,64.124s-3.3-.065-3.3-1.753S17.4,60,17.4,60s-1.7,1.149-1.7,2.362,3.437.136,3.437,1.756ZM24.531,65.2c0,1.616-3.465,2.236-3.465,2.236s1.768-1.149,1.768-2.229-3.3-.065-3.3-1.753,3.26-2.372,3.26-2.372-1.7,1.149-1.7,2.362,3.437.139,3.437,1.756Zm4.313-1.078c0,1.619-3.465,2.236-3.465,2.236s1.768-1.149,1.768-2.229-3.3-.065-3.3-1.753S27.113,60,27.113,60s-1.7,1.149-1.7,2.362,3.434.136,3.434,1.756ZM21.862,87.248C15.31,87.248,10,86.037,10,84.551c0,.031,23.727.031,23.727,0,0,1.486-5.31,2.7-11.865,2.7Z" transform="translate(-10 -60)" />
        </svg>
    );
}
