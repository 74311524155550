import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";

import UserCard from '../pages/user/UserCard';
import UserSearch from '../pages/user/UserSearch';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/scss/main.scss';
import './Layout.scss';
import Login from '../pages/account/Login';
import Register from '../pages/account/Register';
import ForgotPasswordStep1 from '../pages/account/ForgotPasswordStep1';
import ConfirmEmail from '../pages/account/ConfirmEmail';
import ConfirmEmailMessage from '../pages/account/ConfirmEmailMessage';
import ForgotPasswordStep2 from '../pages/account/ForgotPasswordStep2';
import AccessDenied from '../pages/account/AccessDenied';
import Lockout from '../pages/account/Lockout';

import 'bootstrap/dist/js/bootstrap.js';
import BottomMenu from './BottomMenu';
import ConfirmEmailSuccess from '../pages/account/ConfirmEmailSuccess';
import ConfirmEmailError from '../pages/account/ConfirmEmailError';
import ForgotPasswordStep3 from '../pages/account/ForgotPasswordStep3';
import ForgotPasswordStep4 from '../pages/account/ForgotPasswordStep4';
import Error from '../pages/common/Error';
import Routes from '../common/Routes';
import ProfileSettings from '../pages/user/ProfileSettings';
import UserChangePassword from '../pages/user/UserChangePassword';
import UserConfirmEmail from '../pages/user/UserConfirmEmail';
import MeetingList from '../pages/meeting/MeetingList';
import Meeting from '../pages/meeting/Meeting';
import NotificationList from '../pages/notifications/NotificationList';
import TimeTable from '../pages/meeting/TimeTable';
import useAccountStore from '../hooks/useAccountStore';
import ProtectedRoute from './ProtectedRoute';
import PostsList from '../pages/posts/PostsList';
import Contacts from '../pages/contacts/Contacts';



function Layout() {

    const account = useAccountStore();
    const history = useHistory();


    const [isOpenMeeting, setIsOpenMeeting] = useState(false);

    return (
        <Router basename="/">
            <div className="Layout container-xl">

                <Switch>
                    <Route path="/account/">
                        <div className="body">
                            <div className="container-xl">                            

                                <Route path="/contacts"><Contacts /></Route>


                                <Route path="/posts/list"><PostsList /></Route>
                                <Route path="/account/login"><Login /></Route>

                                <Route path="/account/register"><Register /></Route>
                                <Route path="/account/confirmEmailMessage" component={ConfirmEmailMessage} />
                                <Route path="/account/confirmEmail" component={ConfirmEmail} />
                                <Route path="/account/confirmEmailSuccess" component={ConfirmEmailSuccess} />
                                <Route path="/account/confirmEmailError" component={ConfirmEmailError} />

                                <Route path="/account/forgotPasswordStep1"><ForgotPasswordStep1 /></Route>
                                <Route path="/account/forgotPasswordStep2" component={ForgotPasswordStep2} />
                                <Route path="/account/forgotPasswordStep3" component={ForgotPasswordStep3} />
                                <Route path="/account/forgotPasswordStep4" component={ForgotPasswordStep4} />

                                <Route path={Routes.Error} component={Error} />
                                <Route path="/account/accessDenied" component={AccessDenied} />
                                <Route path="/account/lockout" component={Lockout} />
                            </div>
                        </div>
                    </Route>
                    <Route path='/' >



                        <div className="Top row">

                            <div className="Menu">
                                {/*<div className="d-flex justify-content-between justify-content-md-end">*/}
                                {/*    <button className="Button bg-black" >Leave feedback</button>*/}
                                {/*    <Link className="Button bg-blue" to={Routes.Contacts}>Contacts</Link>*/}
                                {/*</div>*/}
                                <div className="Brand">
                                    <h2>Announcement aggregator of Vietnam</h2>
                                    <h1>All newest announcements from facebook for teachers</h1>

                                </div>
                            </div>
                        </div>

                        <div className="row">



                            <Switch>

                                <Route path={Routes.Contacts} render={() => <Contacts />} />
                                <Route exact path="/" render={(routeProps) => <PostsList />} />


                                <>
                                    <ProtectedRoute path={Routes.UserCard} render={(routeProps) => (
                                        <UserCard />

                                    )}></ProtectedRoute>
                                    <ProtectedRoute path={Routes.ProfileSettings} render={(props) => <ProfileSettings />}></ProtectedRoute>
                                    <ProtectedRoute path="/user/Search">
                                        <UserSearch />
                                    </ProtectedRoute>
                                    <ProtectedRoute path={Routes.UserChangePassword} render={props => <UserChangePassword />} />
                                    <ProtectedRoute path={Routes.UserConfirmEmail} render={() => <UserConfirmEmail />} />
                                    <ProtectedRoute path={Routes.MeetingList} render={(props) => <MeetingList {...props} />} />
                                    <ProtectedRoute path={Routes.Meeting} render={(routeProps) => (
                                        <Meeting

                                            setIsOpenMeeting={setIsOpenMeeting}
                                            {...routeProps}
                                        />
                                    )} />
                                    <ProtectedRoute path={Routes.Notifications} render={() => (
                                        <NotificationList />
                                    )} />
                                    <ProtectedRoute path={Routes.TimeTable} render={() => <TimeTable />} />
                                    <Route path={Routes.Error} render={() => <Error />} />

                                </>

                            </Switch>
                        </div>

                        <BottomMenu />

                        <div className=" ">


                            <div className="">
                                
                            </div>

                            


                            {/*{(account.isSignedIn && !isOpenMeeting) &&*/}
                            {/*    <BottomMenu />*/}
                            {/*}*/}

                        </div>
                    </Route>
                </Switch>
            </div>
        </Router>
    );

}

export default Layout;