import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/createStore";
import {  loadCities, selectCity, updatePosts } from "../store/posts";


function usePostsStore(): any {
    const posts = useSelector((state: RootState) => state.posts);
    const dispatch = useDispatch();

    return {
        ...posts,
        update: async () => { await dispatch(updatePosts()); },
        loadCities: async () => { await dispatch(loadCities()); },
        selectCity: async (id: number) => { await dispatch(selectCity(id)); },
    }
}

export default usePostsStore;